import axios from "axios";
import {
    ADD_EDIT_ORDER_ADDRESS,
    ADD_TO_CART,
    PLACE_ORDER,
    REMOVE_ORDER_ITEM,
    UPDATE_DISCOUNT_AMOUNT,
    UPDATE_ORDER,
    UPDATE_SHIPPING_COSTS,
    CREATE_CUSTOMER,
    UPDATE_CUSTOMER,
    EDIT_ORDER_ITEM_IN_CART,
    ADD_CUSTOMER_ORDER,
    SELECT_SHIPPING_METHOD_FOR_SHIPMENT,
    UPDATE_ADDRESS,
    RE_ORDER,
    ADD_EDIT_ORDER_NOTES,
    DELETE_ADDRESS,
    CREATE_ADDRESS,
    ADD_EDIT_STUDENT,
    ALLOCATE_STUDENT
} from "./mutations";
import {
    GET_ORDER,
    GET_CUSTOMER,
    GET_SHIPPING_METHODS,
    GET_PRODUCT_DATA,
    LIST_PAYMENT_GATEWAYS,
    GET_COUNTRIES,
    GET_CUSTOMER_BY_EMAIL,
    GET_CURRENCY_CODES,
    GET_PRODUCT_TAX_CODES_DATA,
    GET_CUSTOMER_ORDERS,
    GET_ORDERS,
    GET_MY_ADDRESSES,
    GET_COURIER,
    GET_LOGGED_IN_CUSTOMER,
    GET_CART_SUGGESTED_PRODUCTS,
    GET_REVIEWS,
    GET_REVIEWS_BY_SKU,
    GET_PRODUCT
} from "./queries";
import { CustomerCreate } from "./types";
import CognitoService from "../cognito";
import {
    AddressType,
    CourierType,
    CurrencyCodeType,
    CustomerType,
    IShipmentService,
    OrderStudent,
    OrderType,
    ProductDataType,
    ProductReviewType,
    ProductType,
    RelatedProductType,
    ReviewType
} from "../../types";
import { getSiteKeys } from "../../utils/config";
import cognito from "../cognito";

const { GQL_ENDPOINT, GQL_API_KEY, tenant, salesChannel } = getSiteKeys();

const apiClient = axios.create({
    method: "POST",
    baseURL: GQL_ENDPOINT,
    headers: {
        "x-api-key": GQL_API_KEY
    }
});

apiClient.interceptors.response.use(
    response => {
        logApiMessages(response); // DEV ONLY
        logErrors(response);
        return response;
    },
    error => {
        console.error("error", error);
        return Promise.reject(error);
    }
);

const logErrors = response => {
    const errors = response?.data?.errors;
    if (errors) {
        for (const key in errors) {
            const error = errors[key];
            if (error?.message) console.error("error", error?.message);
        }
        //alert("Something went wrong.");
    }
};

const logApiMessages = response => {
    const logs = response?.data?.extensions?.console;
    if (logs) {
        for (const key in logs) {
            const log = logs[key];
            console.error("Cyclic Ecommerce Log", log.args);
        }
    }
};

const getCustomer = async (id: string): Promise<CustomerType[] | null> => {
    try {
        const { data } = await apiClient({
            data: {
                query: GET_CUSTOMER,
                variables: { email: id, salesChannel, tenant }
            }
        });

        return data.data.getCustomer;
    } catch (err) {
        console.error("getCustomer", err);
        return null;
    }
};

const getCustomerByEmail = async (email: string): Promise<CustomerType[] | null | "error"> => {
    try {
        const { data } = await apiClient({
            data: {
                query: GET_CUSTOMER_BY_EMAIL,
                variables: { email: email.toLowerCase(), salesChannel, tenant }
            }
        });

        return data.data.getCustomerByEmailSalesChannel.data;
    } catch (err) {
        console.error("getCustomerByEmail", err);
        return "error";
    }
};

const getOrder = async (orderId): Promise<OrderType | null> => {
    try {
        const { data } = await apiClient({
            data: {
                query: GET_ORDER,
                variables: {
                    data: { orderId, tenant, salesChannel, excludeChildItems: true }
                }
            }
        });

        return data?.data?.getOrder;
    } catch (err) {
        console.error("getOrder", err);
        return null;
    }
};

const getCartSuggestedProducts = async (orderId): Promise<RelatedProductType[] | null> => {
    try {
        const { data } = await apiClient({
            data: {
                query: GET_CART_SUGGESTED_PRODUCTS,
                variables: {
                    data: { orderId, tenant, salesChannel }
                }
            }
        });

        return data.data.getCartSuggestedProducts;
    } catch (err) {
        console.error("getCartSuggestedProducts", err);
        return null;
    }
};

const addToCart = async (orderId, skuId, quantity) => {
    try {
        const { data } = await apiClient({
            data: {
                query: ADD_TO_CART,
                variables: {
                    data: { orderId, skuId, quantity, salesChannel, tenant }
                }
            }
        });
        return data?.data?.addToCart;
    } catch (err) {
        console.error("addToCart", err);
        return null;
    }
};

const reOrder = async (input: any) => {
    try {
        const { data } = await apiClient({
            data: {
                query: RE_ORDER,
                variables: {
                    data: input
                }
            }
        });
        return data?.data?.reOrder;
    } catch (err) {
        console.error("reOrder", err);
        return null;
    }
};

const addCustomerOrder = async (
    orderId: string,
    customer: {
        id?: string;
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber?: string;
        phoneNumberCode?: string;
        referalCode?: string;
    }
) => {
    try {
        const { data } = await apiClient({
            data: {
                query: ADD_CUSTOMER_ORDER,
                variables: {
                    data: { orderId, customer, salesChannel, tenant }
                }
            }
        });
        return data?.data?.addCustomerOrder;
    } catch (err) {
        console.error("e", err);
        return null;
    }
};
const createCustomer = async customer => {
    try {
        const { data } = await apiClient({
            data: {
                query: CREATE_CUSTOMER,
                variables: {
                    input: { ...customer, salesChannel, tenant }
                }
            }
        });
        return data?.data?.createCustomer;
    } catch (err) {
        console.error("e", err);
        return null;
    }
};

const editOrderItemQuantity = async (orderId, orderItemId, quantity) => {
    try {
        const { data } = await apiClient({
            data: {
                query: EDIT_ORDER_ITEM_IN_CART,
                variables: {
                    data: { orderId, orderItemId, quantity, salesChannel, tenant }
                }
            }
        });
        return data?.data?.editOrderItem;
    } catch (err) {
        console.error("addToCart", err);
        return null;
    }
};
const removeOrderItem = async (orderId, orderItemId) => {
    try {
        const { data } = await apiClient({
            data: {
                query: REMOVE_ORDER_ITEM,
                variables: {
                    data: {
                        orderId,
                        orderItemId,
                        salesChannel,
                        tenant
                    }
                }
            }
        });
        return data?.data?.removeOrderItem;
    } catch (err) {
        console.error("removeOrderItem", err);
        return null;
    }
};
const addEditOrderAddress = async (
    orderId: string | undefined,
    addresses: AddressType[],
    customer: CustomerType | null
) => {
    try {
        const { data } = await apiClient({
            data: {
                query: ADD_EDIT_ORDER_ADDRESS,
                variables: {
                    data: {
                        orderId,
                        address: addresses,
                        salesChannel,
                        tenant,
                        customerId: customer?.id
                    }
                }
            }
        });
        return data?.data?.addEditOrderAddress;
    } catch (err) {
        console.error("addEditOrderAddress", err);
        return null;
    }
};
const addEditOrderNotes = async (orderId, orderNotes) => {
    try {
        const { data } = await apiClient({
            data: {
                query: ADD_EDIT_ORDER_NOTES,
                variables: {
                    data: { orderId, orderNotes, salesChannel, tenant }
                }
            }
        });
        return data?.data?.addEditOrderNotes;
    } catch (err) {
        console.error("addEditOrderNotes", err);
        return null;
    }
};
const selectShippingMethodForShipment = async (
    orderId: string,
    orderShipmentId: string,
    shippingMethodId: string,
    services: IShipmentService[]
) => {
    try {
        const { data } = await apiClient({
            data: {
                query: SELECT_SHIPPING_METHOD_FOR_SHIPMENT,
                variables: {
                    data: {
                        orderId,
                        tenant,
                        salesChannel,
                        id: orderShipmentId,
                        shippingMethod: shippingMethodId,
                        services
                    }
                }
            }
        });
        return data?.data?.selectShippingMethodForShipment;
    } catch (err) {
        console.error("selectShippingMethodForShipment", err);
        return null;
    }
};
const addEditStudent = async (
    orderId: string,
    student: OrderStudent
): Promise<OrderType | null> => {
    try {
        const { data } = await apiClient({
            data: {
                query: ADD_EDIT_STUDENT,
                variables: {
                    data: {
                        orderId,
                        tenant,
                        salesChannel,
                        ...student
                    }
                }
            }
        });
        return data.data.addEditStudent as OrderType;
    } catch (err) {
        console.error("addEditStudent", err);
        return null;
    }
};
const allocateStudent = async (input: {
    orderId: string;
    shipmentId: string;
    orderItemId: string;
    studentEmail: string;
    index: number;
}): Promise<OrderType | null> => {
    try {
        const { data } = await apiClient({
            data: {
                query: ALLOCATE_STUDENT,
                variables: {
                    data: {
                        tenant,
                        salesChannel,
                        ...input
                    }
                }
            }
        });
        return data.data.allocateStudent as OrderType;
    } catch (err) {
        console.error("allocateStudent", err);
        return null;
    }
};
const getShippingMethods = async () => {
    try {
        const { data } = await apiClient({
            data: {
                query: GET_SHIPPING_METHODS,
                variables: {
                    tenant
                }
            }
        });
        return data.data.getShippingMethods.data;
    } catch (err) {
        console.error("listShippingMethods", err);
        return null;
    }
};
const updateShippingCosts = async (orderId, shippingMethodId) => {
    try {
        const { data } = await apiClient({
            data: {
                query: UPDATE_SHIPPING_COSTS,
                variables: {
                    data: {
                        orderId,
                        shippingMethodId,
                        salesChannel,
                        tenant
                    }
                }
            }
        });
        return data?.data?.updateShippingCosts;
    } catch (err) {
        console.error("updateShippingCosts", err);
        return null;
    }
};

export const getCourier = async (id: string): Promise<CourierType> => {
    const { data } = await apiClient({
        data: {
            query: GET_COURIER,
            variables: {
                id,
                tenant
            }
        }
    });

    return data?.data?.getCourier;
};

const placeOrder = async (orderId, paymentGatewayId, paymentData): Promise<OrderType | null> => {
    paymentData.browserDetails = {
        browserScreenHeight: window.innerHeight,
        browserScreenWidth: window.innerWidth,
        browserLanguage: navigator.language,
        browserJavaEnabled: navigator.javaEnabled(),
        browserColorDepth: screen.colorDepth,
        domain: window.location.origin
    };

    try {
        const { data } = await apiClient({
            data: {
                query: PLACE_ORDER,
                variables: {
                    data: {
                        orderId,
                        paymentGatewayId,
                        paymentData: JSON.stringify(paymentData),
                        tenant,
                        salesChannel
                    }
                }
            }
        });

        return data?.data?.placeOrder;
    } catch (err) {
        console.error("placeOrder", err);
        return null;
    }
};

const getProductTaxCodesData = async (id: string) => {
    try {
        const { data } = await apiClient.post("", {
            query: GET_PRODUCT_TAX_CODES_DATA,
            variables: {
                id,
                tenant
            }
        });

        return data?.data?.getProductTaxCodesData;
    } catch (err) {
        console.error("getProductTaxCodesData", err);
        return null;
    }
};
export const getProduct = async (id: string) => {
    let data: any;

    try {
        data = (
            await apiClient.post("", {
                query: GET_PRODUCT,
                variables: {
                    id,
                    tenant
                }
            })
        )?.data;
        return data.data.getProduct as ProductType;
    } catch (err) {
        console.error("getProduct", err, data?.errors, data);
    }
    return null;
};
const getProductData = async (id: string) => {
    try {
        const { data } = await apiClient.post("", {
            query: GET_PRODUCT_DATA,
            variables: {
                id,
                tenant,
                salesChannel: salesChannel
            }
        });

        return data?.data?.getProductData as ProductDataType;
    } catch (err) {
        console.error("getProduct", err);
    }
    return null;
};

const getPaymentGateways = async () => {
    try {
        const { data } = await apiClient({
            data: {
                query: LIST_PAYMENT_GATEWAYS,
                variables: { tenant_id: tenant }
            }
        });

        const paymentGateways = data?.data?.getPaymentGatewaysForWebsite.data;
        return paymentGateways;
    } catch (err) {
        console.error("Stripe payment gateway", err);
        return null;
    }
};
const updateDiscountAmount = async (
    orderId: string,
    couponCode: string
): Promise<OrderType | null> => {
    try {
        const { data } = await apiClient({
            data: {
                query: UPDATE_DISCOUNT_AMOUNT,
                variables: {
                    data: {
                        orderId,
                        couponCode,
                        salesChannel,
                        tenant
                    }
                }
            }
        });

        return data?.data?.updateDiscountAmount as OrderType;
    } catch (err) {
        console.error("updateDiscountAmount", err);
        return null;
    }
};
const updateOrder = async (orderId: string, input: Partial<OrderType>) => {
    try {
        const { data } = await apiClient({
            data: {
                query: UPDATE_ORDER,
                variables: {
                    id: orderId,
                    data: { ...input, tenant, salesChannel }
                }
            }
        });

        return data?.data?.updateOrder;
    } catch (err) {
        console.error("update rorder", err);
        return null;
    }
};

const validateAddress = address => {
    return (
        address?.firstName &&
        address?.lastName &&
        address?.line1 &&
        address?.city &&
        address?.postCode &&
        address?.countryCode
    );
};

const getCountries = async () => {
    try {
        const { data } = await apiClient({
            data: {
                query: GET_COUNTRIES,
                variables: { tenant: tenant }
            }
        });

        const countries = data?.data?.getCountries.data;
        return countries;
    } catch (err) {
        console.error("contries", err);
        return [];
    }
};

export const getCurrencyCodes = async (): Promise<CurrencyCodeType[]> => {
    const { data } = await apiClient({
        data: {
            query: GET_CURRENCY_CODES,
            variables: { tenant: tenant }
        }
    });

    const currencyCodes = data?.data?.getCurrencyCodes.data;

    return currencyCodes;
};
export const getCustomerOrders = async (customer: CustomerType) => {
    try {
        const { data } = await apiClient({
            data: {
                query: GET_CUSTOMER_ORDERS,
                variables: {
                    data: { tenant, salesChannel, customerId: customer?.id }
                }
            }
        });

        return data?.data?.getCustomerOrders;
    } catch (err) {
        console.error("err", err);
        return [];
    }
};
export const getCustomerAddresses = async (): Promise<AddressType[]> => {
    try {
        const token = await cognito.getAuthToken();

        const { data } = await apiClient({
            headers: {
                Authorization: "Bearer " + token,
                "x-api-key": ""
            },
            data: {
                query: GET_MY_ADDRESSES
            }
        });

        return data?.data?.getMyAddresses as AddressType[];
    } catch (err) {
        console.error("err", err);
        return [];
    }
};
export type UpdateCustomerInput = Required<Pick<CustomerType, "id">> & Partial<CustomerType>;
export const updateCustomer = async (
    custData: UpdateCustomerInput
): Promise<CustomerType | null> => {
    try {
        const token = await cognito.getAuthToken();

        const { data } = await apiClient({
            headers: {
                Authorization: "Bearer " + token,
                "x-api-key": ""
            },
            data: {
                query: UPDATE_CUSTOMER,
                variables: {
                    input: {
                        salesChannel: salesChannel,
                        ...custData
                    }
                }
            }
        });

        return data?.data?.updateCustomer;
    } catch (err) {
        console.error("err", err);
        return null;
    }
};
export const createAddress = async (
    addressData: Partial<AddressType> & { salesChannel: string; addressType: string }
): Promise<AddressType | null> => {
    try {
        const token = await cognito.getAuthToken();

        const { data } = await apiClient({
            headers: {
                Authorization: "Bearer " + token,
                "x-api-key": ""
            },
            data: {
                query: CREATE_ADDRESS,
                variables: {
                    input: addressData
                }
            }
        });

        return data?.data?.createAddress;
    } catch (err) {
        console.error("err", err);
        return null;
    }
};
export const getLoggedInCustomerData = async (): Promise<CustomerType | null> => {
    try {
        const token = await cognito.getAuthToken();

        const { data } = await apiClient({
            headers: {
                Authorization: "Bearer " + token,
                "x-api-key": ""
            },
            data: {
                query: GET_LOGGED_IN_CUSTOMER
            }
        });

        return data?.data?.getLoggedInCustomerData;
    } catch (err) {
        console.error("err", err);
        return null;
    }
};
const updateAddress = async (
    address: AddressType & { customerId: string; addressType: string }
) => {
    try {
        const { data } = await apiClient({
            data: {
                query: UPDATE_ADDRESS,
                variables: {
                    input: {
                        ...address,
                        salesChannel,
                        tenant
                    }
                }
            }
        });

        return data?.data?.updateAddress;
    } catch (err) {
        console.error("updateAddress", err);
        return null;
    }
};
const deleteAddress = async (
    id: string,
    addressType: string,
    customerId: string,
    warehouseId: string
) => {
    try {
        const { data } = await apiClient({
            data: {
                query: DELETE_ADDRESS,
                variables: {
                    id,
                    addressType,
                    customerId,
                    warehouseId,
                    salesChannel,
                    tenant
                }
            }
        });

        return data?.data?.deleteAddress;
    } catch (err) {
        console.error("deleteAddress", err);
        return null;
    }
};

export const getReviews = async (limit: number): Promise<ReviewType[] | undefined> => {
    try {
        const { data } = await apiClient({
            data: {
                query: GET_REVIEWS,
                variables: {
                    tenant,
                    salesChannel,
                    limit
                }
            }
        });

        return data?.data?.getReviews?.data as ReviewType[];
    } catch (err) {
        console.error("getOrder", err);
        return undefined;
    }
};
export const getReviewsBySKU = async (sku: string): Promise<ProductReviewType[] | undefined> => {
    let data: any;

    try {
        data = (
            await apiClient({
                data: {
                    query: GET_REVIEWS_BY_SKU,
                    variables: {
                        sku,
                        tenant
                    }
                }
            })
        )?.data;

        return data.data.getReviewsBySKU as ProductReviewType[];
    } catch (err) {
        console.error("getReviewsBySKU", err, data?.errors, data);
        return undefined;
    }
};

export default {
    deleteAddress,
    reOrder,
    getCustomerAddresses,
    createCustomer,
    getCountries,
    getLoggedInCustomerData,
    getCustomerByEmail,
    validateAddress,
    addCustomerOrder,
    editOrderItemQuantity,
    updateShippingCosts,
    getOrder,
    addToCart,
    removeOrderItem,
    addEditOrderAddress,
    updateOrder,
    getShippingMethods,
    placeOrder,
    getPaymentGateways,
    updateDiscountAmount,
    getCurrencyCodes,
    selectShippingMethodForShipment,
    getProductData,
    getProductTaxCodesData,
    getCustomerOrders,
    updateAddress,
    getCourier,
    updateCustomer,
    createAddress,
    getCartSuggestedProducts,
    addEditStudent,
    allocateStudent,
    getReviews
};
