import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AddressType, CustomerType, IShipmentService, OrderType } from "../../types";
import EcommerceApi from "../../services/ecommerceApi";
import cognito from "../../services/cognito";
import { salesChannel } from "../../config";
import { AppCognitoUser } from "../auth";
import { klaviyoHandleClientData } from "../../services/klaviyo";
import { gtmHandleDataLayer } from "../../services/gtm";
import { ISalesChannel } from "../../services/ecommerceApi/SalesChannel/types";

const NAME = "order";

export const setStep = createAsyncThunk(NAME + "/setStep", async (step: string) => {
    return step;
});

export const getOrder = createAsyncThunk(NAME + "/getOrder", async (orderId: string) => {
    const data = await EcommerceApi.getOrder(orderId);
    return data;
});

export const addToCart = createAsyncThunk(
    NAME + "/addToCart",
    async (params: {
        orderId?: string | null | undefined;
        skuId: string | null | undefined;
        quantity: number;
        salesChannel?: ISalesChannel;
        currencyCode?: string;
    }) => {
        const order = await EcommerceApi.addToCart(params.orderId, params.skuId, params.quantity);
        return {
            order,
            salesChannel: params.salesChannel,
            currencyCode: params.currencyCode
        };
    }
);

export const reOrder = createAsyncThunk(NAME + "/reOrder", async (input: any) => {
    const order = await EcommerceApi.reOrder(input);
    return order;
});

export const removeOrderItem = createAsyncThunk(NAME + "/removeOrderItem", async (params: any) => {
    const data = await EcommerceApi.removeOrderItem(params.orderId, params.orderItemId);
    return data;
});

export const editOrderItemQuantity = createAsyncThunk(
    NAME + "/editOrderItemQuantity",
    async (params: any) => {
        const data = await EcommerceApi.editOrderItemQuantity(
            params.orderId,
            params.orderItemId,
            params.quantity
        );
        return data;
    }
);

export type AddCustomerType = {
    id?: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
    phoneNumberCode?: string;
    referalCode?: string;
};
export const addCustomerOrder = createAsyncThunk(
    NAME + "/addCustomerOrder",
    async (params: { orderId: string; user: AddCustomerType }) => {
        const { user } = params;
        const cusRes = await EcommerceApi.getCustomerByEmail(user.email);
        if (cusRes == "error") throw new Error("Customer not found");
        let customer = cusRes && cusRes.length > 0 ? cusRes[0] : null;
        if (customer) {
            user.id = customer.id;
            user.firstName = user.firstName || customer.firstName;
            user.lastName = user.lastName || customer.lastName;
            user.phoneNumber = user.phoneNumber || customer.phoneNumber;
            user.phoneNumberCode = user.phoneNumberCode || customer.phoneNumberCode;
        }
        const data = await EcommerceApi.addCustomerOrder(params.orderId, user);
        return data;
    }
);

export const addEditOrderAddress = createAsyncThunk(
    NAME + "/setBillingAddress",
    async (params: {
        orderId: string | undefined;
        address: AddressType;
        customer: CustomerType | null;
    }) => {
        const data = await EcommerceApi.addEditOrderAddress(
            params.orderId,
            [params.address],
            params.customer
        );
        return data;
    }
);

export const addEditOrderNotes = createAsyncThunk(
    NAME + "/addEditOrderNotes",
    async (params: any) => {
        const data = await EcommerceApi.updateOrder(params.orderId, params.input);
        return data;
    }
);

export const updateDiscountAmount = createAsyncThunk(
    NAME + "/updateDiscountAmount",
    async ({ orderId, couponCode }: { orderId: string; couponCode: string }) => {
        return await EcommerceApi.updateDiscountAmount(orderId, couponCode);
    }
);

export const updateShippingCosts = createAsyncThunk(
    NAME + "/updateShippingCosts",
    async (params: any) => {
        const data = await EcommerceApi.updateShippingCosts(
            params.orderId,
            params.shippingMethodId
        );
        return data;
    }
);

export const selectShippingMethodForShipment = createAsyncThunk(
    NAME + "/selectShippingMethodForShipment",
    async (params: {
        orderId: string;
        orderShipmentId: string;
        shippingMethodId: string;
        services: IShipmentService[];
    }) => {
        const data = await EcommerceApi.selectShippingMethodForShipment(
            params.orderId,
            params.orderShipmentId,
            params.shippingMethodId,
            params.services
        );
        return data;
    }
);

export const getCustomerAddresses = createAsyncThunk(NAME + "/getCustomerAddresses", async () => {
    const addresses = await EcommerceApi.getCustomerAddresses();
    return addresses;
});

const initialState: {
    stepOpen: string;
    order: OrderType | null;
    orders: OrderType[];
    addresses: AddressType[];
} = {
    order: null,
    stepOpen: "",
    orders: [],
    addresses: []
};
type orderStateType = typeof initialState;
const orderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {
        updateOrder: (state: orderStateType, action) => {
            state.order = action.payload as OrderType;
            return state;
        },
        updateOrders: (state: orderStateType, action) => {
            state.orders = action.payload as OrderType[];
            return state;
        },
        updateAddresses: (state: orderStateType, action) => {
            state.addresses = action.payload as AddressType[];
            return state;
        }
    },
    extraReducers: builder => {
        builder.addCase(setStep.fulfilled, (state: orderStateType, action) => {
            state.stepOpen = action.payload;
        });
        builder.addCase(getOrder.fulfilled, (state: orderStateType, action) => {
            const order = state.order;
            if (order?.status != "shoppingCart") {
                state.order = null;
            } else {
                state.order = action.payload;
            }
            return state;
        });
        builder.addCase(addToCart.fulfilled, (state: orderStateType, action) => {
            const { order, salesChannel, currencyCode } = action.payload;
            handleClientAction("addToCart", salesChannel, order, currencyCode);
            state.order = order;
        });
        builder.addCase(removeOrderItem.fulfilled, (state: orderStateType, action) => {
            state.order = action.payload;
        });
        builder.addCase(editOrderItemQuantity.fulfilled, (state: orderStateType, action) => {
            state.order = action.payload;
        });
        builder.addCase(addCustomerOrder.fulfilled, (state: orderStateType, action) => {
            state.order = action.payload;
        });
        builder.addCase(addEditOrderAddress.fulfilled, (state: orderStateType, action) => {
            state.order = action.payload;
        });
        builder.addCase(addEditOrderNotes.fulfilled, (state: orderStateType, action) => {
            state.order = action.payload;
        });
        builder.addCase(updateDiscountAmount.fulfilled, (state: orderStateType, action) => {
            state.order = action?.payload || state.order;
        });
        builder.addCase(updateShippingCosts.fulfilled, (state: orderStateType, action) => {
            state.order = action.payload;
        });
        builder.addCase(
            selectShippingMethodForShipment.fulfilled,
            (state: orderStateType, action) => {
                state.order = action.payload;
            }
        );
        builder.addCase(reOrder.fulfilled, (state: orderStateType, action) => {
            state.order = action.payload;
        });
        builder.addCase(getCustomerAddresses.fulfilled, (state: orderStateType, action) => {
            state.addresses = action.payload;
        });
    }
});

const handleClientData = (order: OrderType | undefined) => {
    const clientData = order?.clientData || [];
    for (const data of clientData) {
        switch (data.action) {
            case "addToCart":
                klaviyoHandleClientData(data);
                break;
        }
    }
};

export enum ClientAction {
    identify = "identify",
    addToCart = "addToCart",
    removeFromCart = "removeFromCart",
    startedCheckout = "startedCheckout",
    purchase = "purchase",
    viewCart = "viewCart",
    addPaymentInfo = "addPaymentInfo",
    addShippingInfo = "addShippingInfo",
    openForm = "openForm",
    viewItem = "viewItem",
    videoPlay = "videoPlay",
    beginCheckout = "beginCheckout",
    addToCartLink = "addToCartLink"
}
const handleClientAction = (
    action: string,
    salesChannel?: ISalesChannel,
    order?: OrderType,
    currencyCode?: string
) => {
    handleClientData(order);
    switch (action) {
        case "addToCart":
            if (!order || !salesChannel) return;
            gtmHandleDataLayer(
                {
                    action: ClientAction.addToCart,
                    parsed: {
                        order
                    }
                },
                salesChannel,
                currencyCode
            );
            break;
    }
};

export const { updateOrder, updateOrders, updateAddresses } = orderSlice.actions;
export default orderSlice.reducer;
